import React from 'react'
import reviewBg from '../Assets/images/review-bg.png'
import avatar1 from '../Assets/images/avatar1.png'

const ReviewCard = ({ name, img, tag, desc, color }) => {
  return (
    <div className='bg-white rounded drop-shadow-md w-80 md:w-[390px] md:h-[390px] 2xl:w-[400px] 2xl:h-[350px]'>
        <div className='relative'>
            <img src={reviewBg} />
            <div className='flex items-center gap-4 absolute right-5 -bottom-6'>
                <h1 className='font-[Crossword] text-2xl md:text-3xl 2xl:text-4xl'>{name}</h1>
                <img style={{borderColor: color}} className='border-8 rounded-full w-24 md:w-24 2xl:w-auto' src={img} />
            </div>
        </div>
        <div className='p-6 md:pt-2 text-center'>
          <h3 className='font-[Glass] text-lg'>{tag}</h3>
          <p className='font-sans text-xs text-left mt-2 md:mt-5'>{desc}</p>
        </div>
    </div>
  )
}

export default ReviewCard