import React, { useEffect, useRef } from 'react';
import './App.css';
import {BrowserRouter,   Route, Routes} from 'react-router-dom';
import { Home } from './Pages/Home';
import AnimatedCursor from "react-animated-cursor"
import MouseParticles from 'react-mouse-particles'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "animate.css/animate.min.css";
import Sound from "react-sound";
import useSound from 'use-sound';
import music from "./Assets/BOOKFAIR BGM MIX.mp3"

const App = () => {
  // const [play, { stop }] = useSound(
  //   'https://commondatastorage.googleapis.com/codeskulptor-demos/DDR_assets/Kangaroo_MusiQue_-_The_Neverwritten_Role_Playing_Game.mp3',
  //   { volume: 1, loop: true }
  // );

  // useEffect(() => {
  //   play();
  //   return () => stop();
  // }, [play, stop]);

  let screenWidth =
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;
  console.log(screenWidth,"THids is screnn")

  return (
    <div>
      {/* <Sound 
        url='https://dashing-cascaron-63f0f0.netlify.app/BOOKFAIR%20BGM%20MIX.wav'
        playStatus={Sound.status.PLAYING}
        loop
        ref={soundRef}
      /> */}
      <AnimatedCursor 
      innerSize={screenWidth > 1020 ? 20 : 0}
      outerSize={screenWidth > 1020 ? 16 : 0}
      color='135, 110, 241'
      outerAlpha={0.2}
      innerScale={2}
      outerScale={5}
      trailingSpeed={20}
      />
      <MouseParticles g={1} color="random" cull="col,image-wrapper"/>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export {App};
