import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import slider1 from '../../Assets/images/slider1.png';
import slider2 from '../../Assets/images/slider2.png';
import slider3 from '../../Assets/images/slider3.png';
import mobileSlider1 from '../../Assets/images/mobileSlider1.png';
import mobileSlider2 from '../../Assets/images/mobileSlider2.png';
import mobileSlider3 from '../../Assets/images/mobileSlider3.png';
import logo from '../../Assets/images/logo.png';
import cloud from '../../Assets/images/Cloud 1.png';
import butterfly from '../../Assets/images/butterfly.png';
import cover from '../../Assets/images/cover.png';
import arrow from '../../Assets/images/arrow.png';
import learning1 from '../../Assets/images/learning1.png';
import learning2 from '../../Assets/images/learning2.png';
import learning3 from '../../Assets/images/learning3.png';
import bookleft from '../../Assets/images/bookleft.png';
import bookright from '../../Assets/images/bookright.png';
import rabitstill from '../../Assets/images/rabit-still.png';
import rabitstand from '../../Assets/images/rabitstand.png';
import fox from '../../Assets/images/fox.png';
import rabitrun from '../../Assets/images/rabit-run.png';
import zebrabuybook from '../../Assets/images/zebraBuyBook.png';
import bookbutterfly from '../../Assets/images/bookbutterfly.png';
import author from '../../Assets/images/author-2.png';
import squirrelLeft from '../../Assets/images/squirrelLeft.png';
import rabitauthor from '../../Assets/images/rabitauthor.png';
import authorButterfly from '../../Assets/images/authorButterfly.png';
import inputbird from '../../Assets/images/inputbird.png';
import activeSliderDot from '../../Assets/images/activeSliderDot.png';
import sliderDot from '../../Assets/images/sliderDot.png';
import zebraF from '../../Assets/images/zebra-f.png';
import seriesBlob from '../../Assets/images/series_blob.png';
import characBlob from '../../Assets/images/charac_blob.png';
import storyBlob from '../../Assets/images/story_blob.png';
import avatar1 from '../../Assets/images/avatar1.png';
import avatar2 from '../../Assets/images/avatar2.png';
import avatar3 from '../../Assets/images/avatar3.png';
import avatar4 from '../../Assets/images/avatar4.png';
import avatar5 from '../../Assets/images/avatar5.png';
import avatar6 from '../../Assets/images/avatar6.png';
import { BsFacebook, BsTwitter, BsInstagram, BsLinkedin, BsPinterest, BsMailbox, BsEnvelope } from 'react-icons/bs';
import { RiDoubleQuotesL, RiDoubleQuotesR} from 'react-icons/ri';
import { BiMenu } from 'react-icons/bi';
import { Link } from 'react-scroll';
import ScrollAnimation from 'react-animate-on-scroll';
import emailjs from '@emailjs/browser';
import ReviewCard from '../../components/ReviewCard';
import { IoVolumeMediumOutline, IoVolumeMuteOutline  } from "react-icons/io5";

const Home = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuTransparent, setMenuTransparent] = useState(true);
  const form = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  let myaudio = document.getElementById("audio");
  const buyLink = "https://www.amazon.ae/dp/9948774507?ref=myi_title_dp";

  useEffect(() => {
    window.onscroll = (e) => {
      const scrollAmount = document.documentElement.scrollTop;
      if (scrollAmount > 600) {
        setMenuTransparent(false);
      } else{
       setMenuTransparent(true)
      }
    }

  })

  const sendEmail = (e) => {
    e.preventDefault();
    console.log('sendSms')
    emailjs.sendForm('service_5uotzl4', 'template_r6rk61e', form.current, 'oPv3AQ2T_zTPIIyfs')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    appendDots: (dots) => (
      <div>
        <ul className="relative bottom-[60px]">
          {dots.map((item, index) => {
            return (
              <li key={index}>
                {' '}
                <img
                  src={
                    item.props.className === 'slick-active'
                      ? activeSliderDot
                      : sliderDot
                  }
                  alt="sldier"
                />
              </li>
            );
          })}
        </ul>
      </div>
    ),
  };

  const reviewSettings = {
    arrow: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    // autoplay: true,
    prevArrow: <div className="slick-prev">test</div>,
    appendDots: (dots) => (
      <div>
        <ul className="relative bottom-[-20px]">
          {dots.map((item, index) => {
            return (
              <li key={index}>
                {' '}
                <img
                  src={
                    item.props.className === 'slick-active'
                      ? activeSliderDot
                      : sliderDot
                  }
                  alt="sldier"
                />
              </li>
            );
          })}
        </ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1020,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  };

  const Br = () => (
    <br className='hidden md:block' />
  )

  const handlePlayPause = () => {
    if (myaudio.paused) {
      myaudio.play().then(() => {
        setIsPlaying(true);
      }).catch(error => {
        console.error('Play error:', error);
      });
    } else {
      myaudio.pause();
      setIsPlaying(false);
    }
  };

  return (
    <div className='overflow-hidden'>
      <button class="fixed z-[99] bottom-10 right-8 border bg-[#8F54E6] w-14 h-14 md:w-20 md:h-20 rounded-full drop-shadow-lg flex justify-center items-center text-white text-3xl md:text-4xl" onClick={handlePlayPause} >
     { isPlaying  ?<IoVolumeMediumOutline /> :
        <IoVolumeMuteOutline />}
      </button>
      <div className="relative hero h-auto ">
        <div className={`lg:fixed z-[99] py-2 px-2.5 md:px-10 xl:px-24 top-0 left-0 flex w-full h-[80px] ${'bg-[rgba(255,255,255,0.6)]' } `}>
          <div className="flex flex-1 gap-3 justify-start items-center">
            <img src={logo} alt="logo" className="w-[60px]" />
            <div className="flex flex-col gap-0">
              <h1 className="title">Zezu and Zezi</h1>
              <span className="sub-title leading-3">In the Rainbow Forest</span>
            </div>
          </div>

          <div className="flex 2xl:flex-1">
            <ul className={`hidden lg:flex menu items-center justify-between gap-2 lg:gap-5 flex-1`}>
              <li>
                <Link to="series" smooth={true} spy={true} activeClass="active">
                  {' '}
                  Series{' '}
                </Link>
              </li>
              <li>
                {' '}
                <Link
                  to="characters"
                  smooth={true}
                  spy={true}
                  activeClass="active"
                >
                  {' '}
                  Characters{' '}
                </Link>
              </li>
              <li>
                <Link to="story" smooth={true} spy={true} activeClass="active">
                  {' '}
                  The Story{' '}
                </Link>
              </li>
              <li>
                {' '}
                <Link
                  to="learning"
                  smooth={true}
                  spy={true}
                  activeClass="active"
                >
                  Learning{' '}
                </Link>
              </li>
              <li>
                <Link to="author" smooth={true} spy={true} activeClass="active">
                  Author{' '}
                </Link>
              </li>
              <li>
                <Link to="review" smooth={true} spy={true} activeClass="active">
                  Review{' '}
                </Link>
              </li>
              <li>
              <a href={buyLink} target="_blank" rel="noopener noreferrer" >
                <button className="hover:bg-[#F665B5] button text-white px-6 py-1 rounded-lg border-[1px] border-[black]">
                  Buy the Book
                </button>
                </a>
              </li>
            </ul>

            {/* mobile menu  */}
          
          </div>
          <div className="flex lg:hidden gap-4 items-center">
          <a href={buyLink} target="_blank" rel="noopener noreferrer">
            <button className="button hover:bg-[#F665B5] font-[Crossword] text-white px-6 h-[40px] rounded-lg border-[1px] border-[black]">
              Buy
            </button>
          </a>
            <BiMenu className="text-[30px]" onClick={() => setMenuOpen(!menuOpen)} />
          </div>
        </div>

        <div className={`${menuOpen ? 'flex' : 'hidden'} absolute w-full bg-[rgba(255,255,255,0.6)] transition-all ease-linear`}>
          <ul className={`menu items-center flex flex-col justify-between gap-2 flex-1`}>
              <li>
                <Link to="series" smooth={true} spy={true} activeClass="active">
                  {' '}
                  Series{' '}
                </Link>
              </li>
              <li>
                {' '}
                <Link
                  to="characters"
                  smooth={true}
                  spy={true}
                  activeClass="charac-active"
                >
                  {' '}
                  Characters{' '}
                </Link>
              </li>
              <li>
                <Link to="story" smooth={true} spy={true} activeClass="charac-active">
                  {' '}
                  The Story{' '}
                </Link>
              </li>
              <li>
                {' '}
                <Link
                  to="learning"
                  smooth={true}
                  spy={true}
                  activeClass="active"
                  >
                  Learning{' '}
                </Link>
              </li>
              <li>
                <Link to="author" smooth={true} spy={true} activeClass="active">
                  Author{' '}
                </Link>
              </li>
              <li>
                <Link to="review" smooth={true} spy={true} activeClass="active">
                  Review{' '}
                </Link>
              </li>
              <li>
              <a href={buyLink} target="_blank" rel="noopener noreferrer">
                <button className="hover:bg-[#F665B5] button text-white px-6 py-1 rounded-lg border-[1px] border-[black]">
                  Buy the Book
                </button>
              </a>
              </li>
          </ul>
        </div>

        {/* desktop slider  */}

        <div className="hidden md:block">
          <Slider {...settings}>
            <div className="h-auto md:h-auto">
              <img
                src={slider1}
                alt="hero"
                className="flex w-auto md:w-full"
              />
            </div>

            <div className="h-auto">
              <img
                src={slider2}
                alt="hero"
                className=":flex h-[300px] md:h-[100%] w-auto md:w-full"
              />
            </div>

            <div className="h-auto">
              <img
                src={slider3}
                alt="hero"
                className=":flex h-[300px] md:h-[100%] w-auto md:w-full"
              />
            </div>
          </Slider>
        </div>

        {/* mobile slider  */}

        <div className="block md:hidden -mt-20 z-[-10] relative">
          <Slider {...settings}>
            <div className="h-[90vh]">
              <img src={mobileSlider1} alt="hero" className="h-[115%] w-[115%] object-contain" />
            </div>

            <div className="h-[90vh]">
              <img src={mobileSlider2} alt="hero" className="h-[115%] w-[115%] object-contain" />
            </div>

            <div className="h-[90vh]">
              <img src={mobileSlider3} alt="hero" className="h-[115%] w-[115%] object-contain" />
            </div>
          </Slider>
        </div>
      </div>

      <div className="w-full relative">
        <div id="series" className='absolute -top-14'></div>
        <div className="flex relative -top-5 md:-top-10 xl:-top-16 justify-between px-2.5 md:px-10 xl:px-24">
          <ScrollAnimation animateIn="bounceInUp">
            <img
              src={cloud}
              alt="cloud1"
              className="animate__animated animate__pulse animate__infinite cloud"
            />
          </ScrollAnimation>
          {/* <ScrollAnimation animateIn="bounceInUp">
            <div className="flex my-7 md:my-10 xl:my-14 z-[-1] items-end">
              <span className=" text-center font-[Crossword] text-base sm:text-lg md:text-[28px]">
                Engage in an immersive experience by <br /> hovering over the
                images.
              </span>
            </div>
          </ScrollAnimation> */}
          <ScrollAnimation animateIn="bounceInUp">
            <img
              src={cloud}
              alt="cloud2"
              className="animate__animated animate__pulse animate__infinite cloud"
            />
          </ScrollAnimation>
        </div>

        <div className="flex justify-between px-[40px] mt-3 md:mt-0 md:px-36 xl:px-60">
          <ScrollAnimation animateIn="bounceInUp">
            <img
              src={cloud}
              alt="cloud2"
              className="animate__animated animate__pulse animate__infinite cloud"
            />
          </ScrollAnimation>

          <ScrollAnimation animateIn="bounceInUp">
            <img
              src={cloud}
              alt="cloud2"
              className="animate__animated animate__pulse animate__infinite cloud"
            />
          </ScrollAnimation>
        </div>

        <div className="px-2.5 md:px-10 xl:px-24 flex justify-between items-start">
          <img
            src={butterfly}
            alt="cloud2"
            className="animate__animated animate__pulse animate__infinite w-[20px] md:w-[60px] md:h-[80px] mt-[140px]"
          />
          <ScrollAnimation animateIn="bounceInUp">
            <div className="flex flex-col justify-center items-center flex-wrap text-center -mt-16">
              <h1 className="title text-3xl md:text-[50px] relative z-[-1] w-fit">
                <img src={seriesBlob} alt="" className='absolute -top-3 -left-5 md:-top-10 md:-left-10 z-[-2]' />
                Series
              </h1>
              <br />
              <br />
              <div className='flex flex-col justify-center items-center gap-2'>
                <div className='w-full flex gap-4 text-xl md:text-3xl'>
                  <ScrollAnimation animateIn="fadeInRight" delay={200} className='w-1/2'>
                    <h2 className='w-full font-[Glass] text-right leading-none mt-10'>there is joy in rain and sunshine alike,<Br />  leaves and flowers alike</h2>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeInLeft" delay={100} className='w-1/2'>
                    <h2 className='font-league uppercase font-semibold text-left leading-none'>discover the<Br />  joy of reading<Br /> together</h2>
                  </ScrollAnimation>
                </div>
                <div className='w-full flex gap-4 text-xl md:text-4xl'>
                  <ScrollAnimation animateIn="fadeInRight" delay={400} className='w-1/2'>
                    <h2 className='font-[Chewy] text-right leading-none '>bring the beauty<Br /> of nature to your<Br /> little ones</h2>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeInLeft" delay={300} className='w-1/2'>
                    <h2 className='stroke-text font-[Barlow] uppercase text-left leading-none -mt-16 md:-mt-10'>all things<Br /> colourful<Br /> joyful<Br /> happy</h2>
                  </ScrollAnimation>
                </div>
                <div className='w-full flex gap-4 text-xl md:text-3xl'>
                  <ScrollAnimation animateIn="fadeInRight" delay={600} className='w-1/2'>
                    <h2 className='font-[Glass] text-right leading-none'>inspiring love for nature<Br /> through stories</h2>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeInLeft" delay={500} className='w-1/2'>
                    <h2 className='font-league text-left leading-none -mt-16 md:mt-0'>bring a piece of your<Br /> vacation into your<Br /> child’s daily life</h2>
                  </ScrollAnimation>
                </div>
                <div className='w-full flex gap-4 text-xl md:text-4xl'>
                  <ScrollAnimation animateIn="fadeInRight" delay={1600} className='w-1/2'>
                    <h2 className='font-league text-right leading-none md:-mt-6'>kids will forget<Br /> their gadgets</h2>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeInLeft" delay={1400} className='w-1/2'>
                    <h2 className='stroke-text font-[Barlow] uppercase text-left leading-none -mt-10 md:mt-0'>and step into a<Br /> cheerful world of<Br /> nature</h2>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </ScrollAnimation>

          <img
            src={butterfly}
            alt="cloud2"
            className="animate__animated animate__pulse animate__infinite w-[20px] md:w-[60px] md:h-[80px] mt-[100px] scale-x-[-1]"
          />
        </div>
      </div>

      <div className="characters h-[105vh] md:h-[120vh] w-[100vw]">
        <div className="flex justify-center">
          <div className="relative flex flex-col w-[300px] text-center pt-28 sm:pt-48 md:pt-60 xl:pt-[220px]">
            <div id="characters" className='border absolute bottom-40'></div>
            <ScrollAnimation animateIn="bounceInLeft" delay={100}>
              <div  className='relative'>
                <img src={characBlob} alt="" className='w-16 md:w-auto absolute -top-3 left-[20%] md:-top-10 md:left-0 ' />
                <h1 className="title text-3xl md:text-[50px] relative">
                  Characters
                </h1>
              </div>
            </ScrollAnimation>
          </div>
        </div>

        <div className="flex justify-between md:items-start items-center flex-col md:flex-row px-[10px] md:px-[120px] h-[40vh] xs:h-[70vh] md:h-[60vh]">
          <div className="w-auto md:w-[300px] ml-auto md:ml-[100px] text-center flex items-start pt-8 md:items-end h-[100%]">
            <ScrollAnimation animateIn="bounceInLeft">
              <p className="font-[Crossword] xs:bg-[red] relative mx-6 md:mx-0 md:text-xl">
                <span className='md:hidden'>
                <RiDoubleQuotesL className='text-2xl inline -mt-5'/> &nbsp;
                This is the story of Zezu and Zezi. Zezi is Zezu's little
                sister. She is a little pink zebra. And Zezu is a not-so-little
                purple zebra. Zezu amd Zezi live in a beautiful forest called
                the Rainbow forest. &nbsp; <RiDoubleQuotesR className='text-2xl inline -mb-5' />
                </span>
                <span className='hidden md:block'>
                <RiDoubleQuotesL className='text-2xl inline -mt-5'/> &nbsp;
                Zezu and Zezi love to hike around the various hills in the
                Rainbow Forest and make new friends every day. &nbsp; <RiDoubleQuotesR className='text-2xl inline -mb-5' />
                </span>
              </p>
            </ScrollAnimation>
          </div>

          <ScrollAnimation animateIn="bounceInRight">
            <div className="w-auto md:w-[400px] text-center mt-[55vh] md:mt-[60px] ">
              <p className="font-[Crossword] relative md:text-xl">
                <span className='md:hidden'><RiDoubleQuotesL className='text-2xl inline -mt-5'/> &nbsp; Zezu and Zezi love to hike around the various hills in the
                Rainbow Forest and make new friends every day. &nbsp; <RiDoubleQuotesR className='text-2xl inline -mb-5' /></span>
                <span className='hidden md:block'>
                <RiDoubleQuotesL className='text-2xl inline -mt-5'/> &nbsp;
                This is the story of Zezu and Zezi. Zezi is Zezu's little
                sister. She is a little pink zebra. And Zezu is a not-so-little
                purple zebra. Zezu amd Zezi live in a beautiful forest called
                the Rainbow forest.  &nbsp; <RiDoubleQuotesR className='text-2xl inline -mb-5' />
                </span>
              </p>
            </div>
          </ScrollAnimation>
        </div>
      </div>

      <div className="flex relative flex-col md:flex-row gap-10 p-[10px] md:p-[100px]">
        <div id="story" className='absolute -top-6'></div>
        <ScrollAnimation animateIn="flipInY" className='md:w-1/2'>
          <div className="">
            <img src={cover} alt="cover" className="w-[300px] md:w-full" />
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeInRight" className=' w-full'>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col w-full items-center text-center">
              <h1 className="title text-3xl md:text-[50px] relative mb-5">
                <img src={storyBlob} alt="" className='w-16 md:w-auto absolute -top-3 -left-5 md:-top-10 md:-left-10 z-[-1]' />
                The Story
              </h1>
            </div>

            <div className=''>

              <div className='flex flex-col justify-center items-center gap-2'>
                <div className='w-full flex items-center gap-4 font-semibold text-xl md:text-3xl'>
                  <ScrollAnimation animateIn="fadeInRight" delay={200} className='w-1/2'>
                    <h2 className='w-full font-[Chewy] text-right leading-none'>discover the<Br /> seven beautiful<Br /> hills in the<Br /> Rainbow Forest</h2>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeInLeft" delay={100} className='w-1/2'>
                    <h2 className='font-league uppercase font-semibold text-left leading-none md:text-2xl'>full of<Br /> spontaneity</h2>
                  </ScrollAnimation>
                </div>
                <div className='w-full flex gap-4 text-xl md:text-2xl'>
                  <ScrollAnimation animateIn="fadeInRight" delay={400} className='w-1/2'>
                    <h2 className='w-full font-[Glass] text-right leading-none'> detailed illustrations to let<Br /> the child engage with book</h2>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeInLeft" delay={300} className='w-1/2'>
                    <h2 className='stroke-text font-[Barlow] uppercase text-left leading-none -mt-4 md:-mt-8'>illustrations in<Br /> natural colors<Br /> and artistic style</h2>
                  </ScrollAnimation>
                </div>
                <div className='w-full flex gap-4 text-xl md:text-3xl'>
                  <ScrollAnimation animateIn="fadeInRight" delay={600} className='w-1/2'>
                    <h2 className='w-full font-[Chewy] text-right leading-none'>a believable dream<Br /> world</h2>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeInLeft" delay={500} className='w-1/2'>
                    <h2 className='font-league font-semibold text-left leading-none'>discover new details<Br /> every time you read<Br /> the book</h2>
                  </ScrollAnimation>
                </div>
                <div className='w-full flex gap-4 text-lg md:text-2xl'>
                  <ScrollAnimation animateIn="fadeInRight" delay={700} className='w-1/2 -ml-1'>
                    <h2 className='w-full font-[Glass] text-right leading-none -mt-3 md:-mt-7'>a rich tapestry of various shades of<Br /> cuteness, cheer and joy</h2>
                  </ScrollAnimation>
                </div>
                <a href={buyLink} target="_blank" rel="noopener noreferrer">
                  <button className="button hover:bg-[#F665B5] mt-[20px] px-10 py-2 border-[1px] border-[black] rounded-lg text-white font-[Crossword] text-[20px] z-50">
                    Buy Now
                  </button>
                </a>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </div>

      <div
        id="learning"
        className="learning flex px-[10px] md:px-[100px] flex-col z-[-1] md:justify-start justify-center md:flex-row w-auto md:w-[100vw] h-[900px] md:h-auto"
      >
     

        <div className="md:flex-1 md:py-[200px] relative text-center md:text-left">
        <ScrollAnimation animateIn='zoomInUp'>
          
              <div className='flex flex-col justify-center items-center gap-2'>
                <h1 className="title text-[38px] learning-title">Learning</h1>
                <div className='w-full flex items-center gap-4 font-semibold text-xl md:text-2xl'>
                  <div className='w-1/2'>
                  </div>
                  <ScrollAnimation animateIn="fadeInLeft" delay={100} className='w-1/2'>
                    <h2 className='font-league uppercase font-semibold text-left leading-none md:text-2xl'>full of<Br /> spontaneity</h2>
                  </ScrollAnimation>
                </div>
                <div className='w-full flex gap-4 text-lg md:text-3xl'>
                  <ScrollAnimation animateIn="fadeInRight" delay={200} className='w-1/2'>
                    <h2 className='w-full font-[Glass] text-right leading-none -mt-4 md:-mt-8'>learn action words<Br /> climbing, swimming, hiding</h2>
                  </ScrollAnimation>
                  <div className='w-1/2'>
                    <h2 className='stroke-text font-[Barlow] uppercase text-left leading-none'>count the<br/> squirrels</h2>
                  </div>
                </div>
                <div className='w-full flex gap-4 text-lg md:text-3xl'>
                  <ScrollAnimation animateIn="fadeInRight" delay={400} className='w-1/2'>
                    <h2 className='w-full font-[Chewy] text-right leading-none'>count the rabbits</h2>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeInLeft" delay={300} className='w-1/2'>
                    <h2 className='font-[Glass] text-left leading-none'>learn the colors of<Br /> nature - flowers,<Br /> birds, sky</h2>
                  </ScrollAnimation>
                </div>
                <div className='w-full flex gap-4 text-lg md:text-3xl'>
                  <ScrollAnimation animateIn="fadeInRight" delay={600} className='w-1/2'>
                    <h2 className='w-full font-[Glass] text-right leading-none -mt-6 md:-mt-12'>learning that is<Br /> spontaneously woven<Br /> into the story</h2>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeInLeft" delay={500} className='w-1/2'>
                    <h2 className='stroke-text font-[Barlow] uppercase text-left leading-none'>organic fun<Br /> learning</h2>
                  </ScrollAnimation>
                </div>
                <div className='w-full flex items-end gap-4 text-lg md:text-3xl'>
                  <ScrollAnimation animateIn="fadeInRight" delay={700} className='w-1/2'>
                    <h2 className='w-full font-league text-right leading-none -mt-2'>build vocabulary<Br /> with simple easy<Br /> sentences</h2>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeInLeft" delay={800} className='w-1/2'>
                    <h2 className='font-[Glass] text-left leading-none'>interesting words every<Br /> now and then</h2>
                  </ScrollAnimation>
                </div>
              </div>
          </ScrollAnimation>
          <img
            src={fox}
            alt="fox"
            className="hidden md:flex absolute bottom-[-20px]"
          />
          <img
            src={rabitstand}
            alt="rabit"
            className="w-16 md:w-auto absolute z-[1] top-[-90px] md:top-1 left-3 md:left-[400px]"
          />
          <img
            src={squirrelLeft}
            alt="rabit"
            className="w-20 md:w-auto flex md:hidden z-[1] absolute top-[-90px] md:top-1 right-0 md:left-[300px] scale-x-[-1]"
          />
        </div>

        <div className="flex relative flex-col w-auto md:w-1/2 py-[40px] md:py-[200px] px-[20px]">
          <div className="flex justify-evenly gap-4 md:gap-0">
            <ScrollAnimation animateIn="bounceIn">
              <img
                src={learning1}
                alt="learning"
                className="w-[170px] md:w-[220px] 2xl:w-[300px] h-[170px] md:h-[220px] 2xl:h-[300px] mt-[-10px] md:mt-[120px]"
              />
            </ScrollAnimation>
            <ScrollAnimation animateIn="bounceIn">
              <img
                src={learning3}
                alt="learning"
                className="w-[170px] md:w-[220px] 2xl:w-[300px] h-[170px] md:h-[220px] 2xl:h-[300px] mt-[0px]"
              />
            </ScrollAnimation>
          </div>
          <div className="flex justify-center pl-[40px] mt-[-40px] items-start pb-5">
            <ScrollAnimation animateIn="bounceIn">
              <img
                src={learning2}
                alt="learning"
                className="w-[170px] md:w-[220px] 2xl:w-[300px] h-[170px] md:h-[220px] 2xl:h-[300px] mt-[40px] md:mt-0 md:ml-24"
              />
            </ScrollAnimation>
          </div>
          <img
            src={fox}
            alt="fox"
            className="flex md:hidden absolute bottom-[-49px] w-24"
          />
          <img
            src={bookbutterfly}
            alt="learning"
            className="w-[30px] md:w-[60px] absolute top-[180px]"
          />
          <img
            src={bookbutterfly}
            alt="learning"
            className="w-[40px] absolute bottom-[200px] right-14 scale-x-[-1]"
          />
          <img
            src={rabitstill}
            alt="learning"
            className="w-[60px] md:w-[80px] absolute bottom-[-50px] md:bottom-[-20px] right-0 md:right-14 "
          />
          <img
            src={squirrelLeft}
            alt="rabit"
            className="hidden z-[-1] md:flex absolute top-0 right-0 md:left-[300px] scale-x-[-1]"
          />
        </div>
      </div>

      <div className="bg-[#f9f4f0] p-[10px] pt-[100px] md:p-[60px] ">
        <div className="flex flex-col md:flex-row justify-between items-start">
          <div className="pt-[20px] flex items-center justify-center">
            <ScrollAnimation animateIn='rotateIn'>

            <img
              src={bookleft}
              alt="book left"
              className="w-[300px] md:w-auto"
              />
              </ScrollAnimation>
          </div>
              <ScrollAnimation animateIn='rotateInDownLeft' className='md:w-5/6 2xl:w-1/2 flex flex-col justify-center items-center gap-2'>
                <h1 className="title text-[38px] coming-title">Coming Soon</h1>
                <div className='w-full flex items-center gap-4 text-xl md:text-2xl'>
                  <ScrollAnimation animateIn="fadeInRight" delay={100} className='w-1/2'>
                    <h2 className='font-[Glass] text-right leading-none'>more on the way...</h2>
                  </ScrollAnimation>
                  <div className='w-1/2'>
                  </div>
                </div>
                <div className='w-full flex items-end gap-4 text-xl md:text-2xl'>
                  <ScrollAnimation animateIn="fadeInRight" delay={300} className='w-1/2'>
                    <h2 className='w-full font-league font-semibold text-right leading-none'>simple stories with<Br /> messages</h2>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeInLeft" delay={200} className='w-1/2'>
                    <h2 className='font-[Glass] text-left leading-none md:-mb-2'>this is just a beginning</h2>
                  </ScrollAnimation>
                </div>
                <div className='w-full flex items-center gap-4 text-xl md:text-2xl'>
                  <ScrollAnimation animateIn="fadeInRight" delay={400} className='w-1/2'>
                    <h2 className='stroke-text font-[Barlow] uppercase text-right leading-none'>Lets make the<br /> world a better<Br /> place</h2>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeInLeft" delay={500} className='w-1/2'>
                    <h2 className='font-[Chewy] text-left leading-none md:text-2xl'>Zezu and Zezi need a<Br /> lot of love from their little readers</h2>
                  </ScrollAnimation>
                </div>
                <div className='w-full flex gap-4 text-xl md:text-2xl'>
                  <ScrollAnimation animateIn="fadeInRight" delay={700} className='w-1/2'>
                    <h2 className='font-league text-right leading-none font-semibold md:text-2xl'>help bring simple<Br /> nature to more and more children</h2>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeInLeft" delay={600} className='w-1/2'>
                    <h2 className='stroke-text font-[Barlow] uppercase text-left leading-none'>kindness<Br /> humility<Br /> patience</h2>
                  </ScrollAnimation>
                </div>
              </ScrollAnimation>
          <div className="flex justify-center items-center">
            <ScrollAnimation animateIn='rotateIn'>

            <img
              src={bookright}
              alt="book right"
              className="w-[300px] md:w-auto"
              />
              </ScrollAnimation>
          </div>
        </div>
        {/* <div>
          <p className="paragraph text-center text-[20px]">
            This is just a beginning and <b> Zezu and Zezi</b> need a lot of
            love from their little readers to help bring simple things <br /> in
            nature to more and more children and make a world a better place.
          </p>
        </div> */}
      </div>

      <ScrollAnimation animateIn='fadeInUp'>
      <div id="buy" className="bg-[#f9f4f0] p-[10px] pt-[100px] md:p-[100px] text-center">
        <h1 className="title text-[40px] font-regular">
          <span className="buy-title"> Buy the Book </span>
        </h1>
        <div className='flex flex-col justify-center items-center gap-2 mt-10 md:mt-20'>
          <div className='w-full flex gap-4 text-xl md:text-4xl'>
            <ScrollAnimation animateIn="fadeInLeft" delay={100} className='w-1/2'>
              <h2 className='w-full font-[Glass] text-right leading-none'>recently released in the<Br /> prestigious</h2>
            </ScrollAnimation>
            <div className='w-1/2'>
            </div>
          </div>   
          <div className='w-full flex gap-4 text-xl md:text-4xl'>
            <ScrollAnimation animateIn="fadeInRight" delay={300} className='w-1/2'>
              <h2 className='w-full font-league font-semibold text-right leading-none'>now available<Br /> for purchase in book stores<Br /> and amazon</h2>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInLeft" delay={200} className='w-1/2'>
              <h2 className='stroke-text font-[Barlow] uppercase text-left leading-none md:text-5xl -mt-5 md:-mt-10'>Sharjah<Br /> International<Br /> Book Fair</h2>
            </ScrollAnimation>
          </div>   
          <div className='w-full flex gap-4 text-xl md:text-4xl'>
            <ScrollAnimation animateIn="fadeInRight" delay={500} className='w-1/2'>
              <h2 className='w-full font-[Glass] text-right leading-none'>Now available for purchase in various<Br /> bookstores across UAE and on Amazon.<Br /> Click the link below and bring the magical<Br />  world of reading to your children!</h2>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInLeft" delay={400} className='w-1/2'>
              <h2 className='font-[Chewy] text-left leading-none -mt-14 md:mt-0'>Dont forget to check out our instagram<Br /> page for valuable resources for reading,<Br /> parenting and giveaways!</h2>
            </ScrollAnimation>
          </div>
          <a href={buyLink} target="_blank" rel="noopener noreferrer">
            <button className="button hover:bg-[#F665B5] px-[60px] py-2 text-[20px] font-[Crossword] text-white rounded-full border-[black] border-[1px] my-6 md:mt-10">
              {' '}
              Buy now
            </button>
          </a> 
          <h2 className='stroke-text font-[Barlow] uppercase text-xl md:text-5xl'>
            Like, subscribe and share, or leave your feedback.<br /> Share your photos with the book to win goodies every now and then
          </h2>  
        </div>
      </div>
      </ScrollAnimation>

      <div className="buynow relative bg-[#f9f4f0] md:px-[100px] md:pt-[100px] mt-[100px] ">

        {/* <div className="flex flex-col items-center md:flex-row justify-evenly">
          <div className="flex flex-col pt-[40px]">
            <span className="title">Name</span>
            <input name='name' className="input w-[260px] md:w-[420px] md:h-[70px] bg-white rounded-full font-[Crossword] text-[36px] px-2" />
          </div>
          <div className="flex flex-col">
            <span className="title">Email Id</span>
            <input name='email' className="input w-[260px] md:w-[420px] md:h-[70px] bg-white rounded-full font-[Crossword] text-[36px] px-2" />
          </div>
        </div> */}

        <div className="flex justify-center items-center py-[60px]">
          {/* <a href="mailto:findus@zezuandzezi.com">
          <button className="button hover:bg-[#F665B5] px-[60px] py-2 text-[20px] font-[Crossword] text-white rounded-full border-[black] border-[1px]">
            {' '}
            Send us an Email{' '}
          </button>
          </a> */}
        </div>

        <img
          src={rabitrun}
          alt="zebrabook"
          className="absolute w-[90px] md:w-auto top-[-20px] md:top-[-40px] md:left-1/2  "
        />
        <img
          src={bookbutterfly}
          alt="zebrabook"
          className=" hidden md:flex absolute top-16"
        />
        <img
          src={zebrabuybook}
          alt="zebrabook"
          className="absolute w-[140px] md:w-[260px] right-4 md:bottom-0 -top-36 md:top-[-100px]"
        />
      </div>

      <div className="bg-[#f9f4f0] p-[10px] md:p-[100px] flex justify-center items-center">
        <ScrollAnimation animateIn='fadeInLeft' className='w-full'>
        <div className='flex flex-col justify-center items-center gap-2'>
          <h1 className="title text-[38px] share-title">Share Your thoughts</h1>
          <div className='w-full flex items-center gap-4 text-xl md:text-3xl'>
            <ScrollAnimation animateIn="fadeInRight" delay={100} className='w-1/2'>
              <h2 className='font-[Glass] text-right leading-none'>We’d love to hear from you</h2>
            </ScrollAnimation>
            <div className='w-1/2'>
            </div>
          </div>
          <div className='w-full flex gap-4 text-xl md:text-4xl'>
            <ScrollAnimation animateIn="fadeInRight" delay={300} className='w-1/2'>
              <h2 className='w-full font-league text-right leading-none mb-2'>book review</h2>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInLeft" delay={200} className='w-1/2'>
              <h2 className='stroke-text font-[Barlow] uppercase text-left leading-none'>feedback<Br /> suggestions for<Br /> future books</h2>
            </ScrollAnimation>
          </div>
          <div className='w-full flex gap-4 text-xl md:text-3xl'>
            <ScrollAnimation animateIn="fadeInRight" delay={400} className='w-1/2'>
              <h2 className='w-full font-[Glass] text-right leading-none -mt-11 md:-mt-20'>collaboration for<Br /> community projects</h2>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInLeft" delay={500} className='w-1/2'>
              <h2 className='font-[Chewy] text-left leading-none'>we are all ears!</h2>
            </ScrollAnimation>
          </div>
        </div>
        <div className='text-center mt-8'>
          <a href="mailto:findus@zezuandzezi.com">
            <button className="button hover:bg-[#F665B5] px-[60px] py-2 text-[20px] font-[Crossword] text-white rounded-full border-[black] border-[1px] mt-5">
              {' '}
              Send us an Email{' '}
            </button>
          </a>
        </div>
        </ScrollAnimation>
        
        {/* <ScrollAnimation animateIn='fadeInRight'>
          <div className="flex-1 flex justify-center items-center flex-col gap-[40px]">
            <div className="flex flex-col md:flex-row gap-2 justify-evenly">
              <div className="flex flex-col md:w-1/2">
                <span className="title">Name</span>
                <input className="input h-[60px] bg-[transparent] rounded-full font-[Crossword] text-[36px] px-2" />
              </div>
              <div className="flex flex-col md:w-1/2">
                <span className="title">Email Id</span>
                <input className="input h-[60px] bg-[transparent] rounded-full font-[Crossword] text-[36px] px-2" />
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex justify-between">
                <span className="title">Thoughts</span>
                <img
                  src={inputbird}
                  alt="input"
                  className="relative top-[14px]"
                  />
              </div>
              <textarea className="textarea h-[100px] bg-[transparent] font-[Crossword] text-[27px] px-2" />
            </div>
          </div>
        </ScrollAnimation> */}
      </div>

      <div
        id="author"
        className="author bg-[#f9f4f0] justify-center flex px-2.5 md:px-10 xl:px-24 flex-col md:flex-row py-[100px]"
      >
        <div className="flex-1 relative flex justify-center ">
          <ScrollAnimation animateIn='fadeInUp'>
          <img src={author} alt="author" className="w-[300px] md:w-auto" />
          </ScrollAnimation>
          <img
            src={squirrelLeft}
            alt="squirrel"
            className="hidden md:flex absolute bottom-[-100px] right-0"
          />
        </div>
        <div className="flex-1 relative flex flex-col justify-center pt-[100px] md:pt-0 text-center md:text-left">
          <ScrollAnimation animateIn='fadeInDown'>
          <div className='flex flex-col justify-center items-center gap-2 '>
            <h1 className="title author-title text-[38px] p-[20px]">About the Author</h1>   
            <div className='w-full flex gap-4 text-xl md:text-2xl'>
              <div className='w-1/2'>
              </div>
              <ScrollAnimation animateIn="fadeInLeft" delay={100} className='w-1/2'>
                <h2 className='font-league uppercase text-left leading-none font-semibold'> successful<Br /> architect</h2>
              </ScrollAnimation>
            </div>      
            <div className='w-full flex gap-4 text-xl md:text-4xl'>
              <ScrollAnimation animateIn="fadeInRight" delay={200} className='w-1/2'>
                <h2 className='w-full font-[Glass] text-right leading-none -mt-5 md:-mt-10'>designer, entrepreneur<Br /> mentor</h2>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInLeft" delay={300} className='w-1/2'>
                <h2 className='stroke-text font-[Barlow] uppercase text-left leading-none'>mother of two<Br /> beautiful children</h2>
              </ScrollAnimation>
            </div>      
            <div className='w-full flex gap-4 text-xl md:text-4xl'>
              <ScrollAnimation animateIn="fadeInRight" delay={400} className='w-1/2'>
                <h2 className='w-full font-[Chewy] text-right leading-none -mt-6 md:-mt-10'>role of stories in her own parenting inspired her to embark on this journey of writing children's books</h2>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInLeft" delay={500} className='w-1/2'>
                <h2 className='font-[Glass] text-left leading-none'>Her love for nature and all things joyful, beautiful, and spontaneous makes for endearing and enjoyable reading</h2>
              </ScrollAnimation>
            </div>      
            <div className='w-full flex gap-4 text-xl md:text-4xl'>
              <ScrollAnimation animateIn="fadeInRight" delay={600} className='w-1/2'>
                <h2 className='stroke-text font-[Barlow] uppercase text-right leading-none -mt-6 md:-mt-2'>committed to creating inspiring books and bringing Zezu and Zezi to life</h2>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInLeft" delay={700} className='w-1/2'>
                <h2 className='font-league text-left leading-none'>she has a flair for effortlessly weaving human values and learning into fiction</h2>
              </ScrollAnimation>
            </div>      
          </div>
          </ScrollAnimation>
          <img
            src={authorButterfly}
            alt="Butterfly"
            className="absolute top-[0px] lg:w-24 lg:-right-20  2xl:right-0"
          />
          <img
            src={rabitauthor}
            alt="squirrel"
            className="absolute w-[80px] md:w-auto bottom-[-105px] right-0"
          />
          <img
            src={squirrelLeft}
            alt="squirrel"
            className="flex w-[70px] md:hidden absolute bottom-[-100px] left-0"
          />
        </div>
      </div>

      <ScrollAnimation animateIn='fadeInLeft'>
      <div className="review-box px-2.5 md:px-10 2xl:px-24 bg-[#f9f4f0] py-[60px] flex flex-col justify-center items-center gap-10 relative">
        <div id='review' className='absolute -top-0'></div>
        <h1 className="title text-[38px] share-title">Reviews</h1>
        <Slider className='w-80 md:w-96 lg:w-full' {...reviewSettings}>
          <ReviewCard name="Julia Smely" img={avatar1} tag="HR professional and mom" color="#FD54E5" desc="This fun, lighthearted and colourful picture book is full of engaging illustrations. The book describes playfully daily moments - the journey of Zezu & Zezi - that small kids can easily relate to. There's so much to teach - colours, shapes or herbs through this simple story set in a beautiful forest. Kids will be captivated every day with the lovely illustrations full of life. I sure loved reading it to my little one!" />
          <ReviewCard name="Ahlam Bolooki" img={avatar2} tag="CEO and mom" color="#7F00FF" desc="This book is a vibrant and fun to read children’s story. It beautifully brings to life elements of nature that children love and recognize in early childhood. It’s educational and entertaining with fun rhymes that lend children the opportunity to get creative with the sound of words, while practicing their vocabulary and pronunciation. It also offers an opportunity to learn about diverse climates, animals and foods which cultivate a connection between children and the natural world around them. Kanupriya has a refreshing storytelling style and I look forward to reading it with my daughter, and the upcoming ones in the series." />
          <ReviewCard name="Saida Faraj Awadh" img={avatar3} tag="Banker and mom" color="#FD54E5" desc="This book has everything I want to teach my two toddlers - colours, trees, flowers, animals, sounds and so much more. Zezu and Zezi are so cute, they are already my childrens favourite characters. I love the last page which is so helpful to make the reading time fun and educational at the same time. Even my youngest is so fascinated by the colours and drawings in the book. I cant wait to read the upcoming books too. I hope the author continues to write more and more!" />
          <ReviewCard name="Niamh Flynn" img={avatar4} tag="Teacher" color="#7F00FF" desc="Zezu and Zezi is everything I expect a children’s book to be. It’s bright and beautiful colours and soft round edges is what attracted me to the book. The author really thought about the children when writing this book and focused on what attracts and interest the child. The mentioning of colours, shapes and sounds were used brilliantly which also aids with a child’s learning by recognition of the different colours and sounds whilst linking them to an object. Overall this is a wonderful book that I will definitely be purchasing and reading to my students during story time." />
          <ReviewCard name="Monique Jamal" img={avatar5} tag="Teacher and mom of 3" color="#FD54E5" desc="This is a wonderful book teaching children the names of different animals, colours, and days of the week. The insightful adventures of the two little Zebras are both exciting and educational. I love how the text is just the right amount for the age group. It's definitely a mustread for all younger year students and teachers. I definitely recommend this beautiful and colourful book." />
          <ReviewCard name="Donna Drennan" img={avatar6} tag="Teacher and mom" color="#7F00FF" desc="This is a beautifully written, educational story. The author has brilliantly incorporated colours, days of the week, senses, weather and so much more hidden in the pages of this story. I am sure kids of all ages will find something to engage with in the book. I love how it incorporates a helpful guide for the grown reader enjoying the book with little ones. Reading with little ones is such a beautiful way of nurturing a bond between parent and child. This book is the perfect way to do that! A must read for parents and their little ones. " />
        </Slider>
      </div>
      </ScrollAnimation>

      <div className="flex md:px-[100px] p-[10px] flex-col md:flex-row bg-[#f9f4f0] py-[60px]">
        <div className="flex-1 flex flex-col gap-3 justify-between">

          <div className="hidden md:flex fle gap-3 justify-start items-center">
            <img src={logo} alt="logo" className="w-[120px]" />
            <div className="flex flex-col gap-0">
              <h1 className="title text-4xl">Zezu and Zezi</h1>
              <span className="sub-title leading-3 text-2xl mt-2">In the Rainbow Forest</span>
            </div>
          </div>
        </div>

        <div className="flex-1 pt-[40px] md:pt-0">
          <div className="flex-1 flex flex-col justify-between">

            <div className="flex md:hidden flex-1 gap-3 justify-center py-10 items-center">
              <img src={logo} alt="logo" className="w-[120px]" />
              <div className="flex flex-col gap-0">
                <h1 className="title text-4xl">Zezu and Zezi</h1>
                <span className="sub-title leading-3 text-2xl mt-2">
                  In the Rainbow Forest
                </span>
              </div>
            </div>

            <div className="text-center md:text-left">
              <h1 className="title text-[38px]">Share the love </h1>
              <ul className="menu mt-[20px] flex justify-center md:justify-start items-center gap-4 flex-1">
                <a href="https://instagram.com/juniorbookbuddies?igshid=MWlqZDRpcWcwaWUyMA==">
                  <li className="icon">
                    {' '}
                    <BsInstagram />{' '}
                  </li>
                </a>
                <a href="https://pin.it/5JMN2Ie">
                  <li className="icon">
                    {' '}
                    <BsPinterest />{' '}
                  </li>
                </a>
                <a href="mailto:findus@zezuandzezi.com">
                  <li className="icon">
                    {' '}
                    <BsEnvelope />{' '}
                  </li>
                </a>
                {/* <li className="icon">
                  {' '}
                  <BsInstagram />{' '}
                </li> */}
                {/* <li className="icon">
                  {' '}
                  <BsLinkedin />{' '}
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="footer relative p-[60px] md:pb-[100px] bg-[#f9f4f0] h-[240px]">
        <p className="paragraph text-center mt-[100px] xl:mt-[120px] ">
          @Copyright 2023 all rights reserved by Kanupriya Kuletha
        </p>

        <img
          src={zebraF}
          alt="zebra footer"
          className="w-48 md:w-auto absolute -top-24 md:top-[-140px] -right-16 md:right-[100px]"
        />
      </div>
    </div>
  );
};

export { Home };
